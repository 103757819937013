import { IMonthlyObject } from '../../../components/customerPages/homework/types';
import {
    changeHomeworkMonth,
    reset,
    resetCustomer,
    setFinancialHealth,
    setFinancialIndependence,
    setFinancialIndependenceChart,
    setFlow,
    setHomework,
    setHwPortfolioMap,
    setImplementations,
    setIncomingDestination,
    setInvestmentPortfolio,
    setRentability,
    setRiskManagement,
    setSuccessionWithdraw,
    updateHomeworkYear,
} from '../../actions/customerActions';
import { updateTotalSaved } from '../../actions/planningActions';
import { ReduxAction } from '../../actions/reduxAction';
import { ICustomerRedux } from '../../types/customer';
import { initialState } from './initialState';

const customerReducer = (state = initialState, action: ReduxAction<any>): ICustomerRedux => {
    switch (action.type) {
        case setFinancialHealth:
            return { ...state, financialHealth: { ...action.payload } };
        case setRentability:
            return { ...state, rentability: action.payload };
        case setFinancialIndependence:
            return { ...state, financialIndependence: { ...action.payload } };
        case setIncomingDestination:
            return { ...state, incomingDestination: { ...action.payload } };
        case setFlow:
            return { ...state, flow: { ...action.payload } };
        case setHomework:
            return { ...state, homework: { ...action.payload } };
        case setInvestmentPortfolio:
            return { ...state, investmentPortfolio: { ...action.payload } };
        case setRiskManagement:
            return { ...state, riskManagement: { ...action.payload } };
        case setSuccessionWithdraw:
            return { ...state, successionWithdraw: action.payload };
        case setFinancialIndependenceChart:
            return { ...state, financialIndependence: { ...state.financialIndependence, projection: action.payload } };
        case updateHomeworkYear:
            return { ...state, homework: { ...state.homework, currentYear: action.payload } };
        case setHwPortfolioMap:
            const key = action.payload.current ? 'current' : 'suggested';
            return {
                ...state,
                investimentPage: {
                    porfolioSummary: {
                        ...state.investimentPage.porfolioSummary,
                        [key]: {
                            classLabel: action.payload.classLabel,
                            assets: action.payload.portfolioEntry,
                        },
                    },
                },
            };
        case reset:
            return { ...initialState, rentability: state.rentability };
        case setImplementations:
            const payload = action.payload as IMonthlyObject[][];
            const newYearly = state.homework.yearlyGoals.map((year, i) => {
                return { ...year, implementations: payload[i] };
            });
            return { ...state, homework: { ...state.homework, yearlyGoals: newYearly } };
        case resetCustomer:
            return initialState;
        case changeHomeworkMonth:
            return { ...state, homeworkPage: { ...state.homeworkPage, currentMonth: action.payload } };
            
        case updateTotalSaved:
            const yearlyGoals = [...state.homework.yearlyGoals].map((item, index) =>
                index === action.payload.index
                    ? { ...item, yearAmount: action.payload.total }
                    : { ...item, yearAmount: item.monthlyGoals.reduce((acc, goal) => acc + goal.saved, 0) }
            );

            const totalAcc = yearlyGoals.reduce((prev, curr) => prev + curr.yearAmount, 0);

            // cut from initial year to current year
            // reduce all into a number expected
            // if last year from previous slice, than cut out lasting months
            // otherwise reduce all months into a sum
            const expectedAcc = yearlyGoals.slice(0, state.homework.currentYear + 1).reduce(
                (prev, curr, index, original) =>
                    prev +
                    (index === original.length - 1
                        ? curr.monthlyGoals
                            .slice(0, state.homeworkPage.currentMonth + 1)
                            .filter(item => item.saveGoal > 0)
                            .reduce((last, next) => last + next.saveGoal, 0)
                        : curr.monthlyGoals
                            .filter(item => item.saveGoal > 0)
                            .reduce((last, next) => last + next.saveGoal, 0)),
                0
            );

            return {
                ...state,
                homework: {
                    ...state.homework,
                    financialIndependenceGoals: {
                        ...state.homework.financialIndependenceGoals,
                        updatedAccumulated: totalAcc + state.homework.financialIndependenceGoals.totalAccumulated,
                        accumulated: totalAcc,
                        expectedAccumulated: expectedAcc,
                    },
                    yearlyGoals,
                },
            };
        default:
            return state;
    }
};

export default customerReducer;
