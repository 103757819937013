import {
    Badge,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Timeline } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { ChangeFlow, ChangeRentability } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { lineSpacing } from '../../../../theme/spacingBetweenComponents';
import formatter from '../../../global/formater';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';
import { ICustomerPageProps } from '../../types';
import Comparison from '../comparion/comparison';
import ProfileSelector from '../profileSelector/profileSelector';
import Suggestions from '../suggestions/suggestions';
import { IFlow, IFlowTableData, IPatrimonyProjection } from '../types';
import { useStyles } from './style';

const verifyLength = (flow: IFlowTableData): string => {
    const { planningDuration: planningPeriod, pensionPayment, planningYear, ...flowArrays } = flow;
    const valid = Object.values(flowArrays).reduce(
        (prev: boolean, curr: number[]) => prev && curr.length === planningPeriod,
        true
    );
    if (!valid) {
        console.debug('All array must be the same size!');
        return 'Informação de servidor inválida!';
    }

    return '';
};

const getTypographyColored = (total: number) => {
    return (
        <Typography style={{ fontSize: '0.875rem' }} color={total < 0 ? 'error' : undefined}>
            {formatter.format(Math.abs(total))}
        </Typography>
    );
};

const Flow: React.FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [, setErr] = React.useState('');

    const { rentability, flow } = useSelector((store: IStore) => ({
        rentability: store.customer.rentability,
        flow: store.customer.flow,
    }));

    let regex = /"/gi;
    const userIDStorage: string = String(localStorage.getItem('userID')).replace(regex, "");

    React.useEffect(() => {
        dispatch(SelectCurrentPage(pageNumber));
    }, []);

    const todayDate = new Date();

    const [loading, setLoading] = React.useState(true);
    const [monthly, setMonthly] = React.useState(false);

    const fetchFinancial = React.useCallback(
        async (rent: number) => {

            if (
                localStorage.getItem('flow' + userID) != undefined ||
                localStorage.getItem('flow' + userID) != null
            ) {
                dispatch(ChangeFlow(JSON.parse(String(localStorage.getItem('flow' + userID)))));
                setLoading(false);

                if (!rent) {
                    setLoading(false);
                    return;
                }

                let url = `/flow?rentability=${rent}`;

                if (userID) {
                    url += `&userID=${userIDStorage || userID}`;
                    //url += `&rate=${flow.actions.rate}`;
                }

                const response = (await api.get<IFlow>(url)).data;

                const lengthError = verifyLength(response.table);

                if (String(localStorage.getItem('flow' + userID)) !== JSON.stringify(response)) {
                    if (!lengthError) {
                        localStorage.setItem('flow' + userID, JSON.stringify(response));
                        dispatch(ChangeFlow(response));
                        setLoading(false);
                    }
                }
            } else {
                if (!rent) {
                    setLoading(false);
                    return;
                }

                let url = `/flow?rentability=${rent}`;

                if (userID) {
                    url += `&userID=${userIDStorage || userID}`;
                    //url += `&rate=${flow.actions.rate}`;
                }

                const response = (await api.get<IFlow>(url)).data;

                const lengthError = verifyLength(response.table);
                if (!lengthError) {
                    localStorage.setItem('flow' + userID, JSON.stringify(response));
                    dispatch(ChangeFlow(response));
                    setLoading(false);
                }

                setErr(lengthError);
            }

            setLoading(false);
        },
        [userID]
    );

    React.useEffect(() => {
        const fetchUserRentability = async () => {
            const params = new URLSearchParams();
            if (userID) {
                params.append('userID', userIDStorage || userID);
            }
            const url = `/customer/rentability?` + params.toString();
            try {
                const { rentability: userRentability } = (await api.get<{ rentability: number }>(url)).data;
                dispatch(ChangeRentability(userRentability));
                fetchFinancial(userRentability);
            } catch {
                fetchFinancial(rentability);
            }
        };
        fetchUserRentability();
    }, []);

    const currentYear = flow.table.planningYear;

    const contemplation = (index: number) => flow.table.patrimonyProjection[index].contemplation;
    const financing = (index: number) => flow.table.patrimonyProjection[index].financing;

    const handleChange = (
        event: React.ChangeEvent<{
            value: unknown;
        }>
    ) => {
        const isMonthly = event.target.value === 1 ? true : false;
        setMonthly(isMonthly);
    };

    const tooltip = (patrimony: IPatrimonyProjection, yearIndex: number, monthly: boolean, monthsToTheEnd: number) => (
        monthly
            ?
            <span>
                {[...Array(patrimony)].map(() => (
                    <>
                        {patrimony.contemplation && patrimony.contemplation > 0 ? (
                            <p>Consórcio: {formatter.format(((patrimony.contemplation / 12) * monthsToTheEnd) / monthsToTheEnd)}</p>
                        ) : null}
                    </>
                ))}
                {patrimony.financing && patrimony.financing > 9 ? (
                    <p>Financiamento: {formatter.format(((patrimony.financing / 12) * monthsToTheEnd) / monthsToTheEnd)}</p>
                ) : null}
                {flow.houseSell.yearIndex === yearIndex && flow.houseSell.value > 0 ? (
                    <p>Venda de imóvel: {formatter.format(((flow.houseSell.value / 12) * monthsToTheEnd) / monthsToTheEnd)}</p>
                ) : null}
            </span>
            :
            <span>
                {[...Array(patrimony)].map(() => (
                    <>
                        {patrimony.contemplation && patrimony.contemplation > 0 ? (
                            <p>Consórcio: {formatter.format(patrimony.contemplation)}</p>
                        ) : null}
                    </>
                ))}
                {patrimony.financing && patrimony.financing > 9 ? (
                    <p>Financiamento: {formatter.format(patrimony.financing)}</p>
                ) : null}
                {flow.houseSell.yearIndex === yearIndex && flow.houseSell.value > 0 ? (
                    <p>Venda de imóvel: {formatter.format(flow.houseSell.value)}</p>
                ) : null}
            </span>
    );

    const invisible = (index: number) => {
        return !(contemplation(index) > 0 || financing(index) > 0 || index === flow.houseSell.yearIndex);
    };

    type projections =
        | 'incomingProjection'
        | 'financingProjection'
        | 'fixedCostProjection'
        | 'consortiumProjection'
        | 'insuranceProjection';
    const getMonthly = (index: number, key: projections): number => {
        if (index === 0) {
            return flow.table[key][index] / flow.monthsToTheEnd;
        }
        if (index !== 0 || flow.table[key].length < 2) {
            return flow.table[key][index] / 12;
        }
        return flow.table[key][1] / 12;
    };

    return (
        <LoadingPage loading={loading}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <Timeline style={{ fill: '#16191d' }} />
                    <Typography variant="h6" className={classes.heading} align="left">
                        Fluxo Financeiro
                    </Typography>
                    <div className={classes.grow} />
                    <ProfileSelector
                        fetchFinancial={fetchFinancial}
                        userID={userIDStorage || userID}
                        rentability={rentability}
                        userRentability={flow.userRentability || rentability}
                    />
                </div>
                <Comparison
                    achievedIncoming={flow.chart.achievedIncoming}
                    independenceYear={flow.chart.independenceYear}
                    neededForIndependence={flow.chart.neededForIndependence}
                    projection={flow.chart.projectionChart}
                    interestAcc={flow.chart.interestAcc}
                    patrimonyAtIndependence={flow.chart.patrimonyAtIndependence}
                />
                {lineSpacing()}
                <Suggestions
                    actions={flow.actions}
                    patrimonyLag={flow.chart.patrimonyAtIndependence - flow.chart.neededForIndependence}
                    rentability={rentability}
                    userID={userIDStorage || userID}
                />
                {lineSpacing()}
                <div className={classes.monthSelector}>
                    <div className='d-flex'>
                        Legenda
                        <div className='d-flex align-items-center ms-2'>
                            <svg width="30" height="10" style={{ marginRight: 10 }}>
                                <line x1="0" y1="5" x2="30" y2="5" style={{ strokeWidth: 2, stroke: 'red' }} />
                            </svg>
                            Décimo ano de projeto
                        </div>
                    </div>
                    <Select
                        labelId="monthly-selector"
                        id="monthly-select"
                        color="primary"
                        input={<OutlinedInput classes={{ input: classes.input }} />}
                        value={monthly ? 1 : 0}
                        defaultValue={1}
                        onChange={handleChange}
                        style={{ width: '150px' }}
                    >
                        <MenuItem value={1}>Mensal</MenuItem>
                        <MenuItem value={0}>Anual</MenuItem>
                    </Select>
                </div>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    <Typography variant="overline">ANO</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="overline">RENDA</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="overline">
                                        GASTOS FIXOS {'&'} PRESTAÇÕES {'&'} CONSÓRCIOS
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="overline">SEGURO DE VIDA</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="overline">
                                        QUALIDADE DE VIDA {'&'} AQUISIÇÃO DE BENS
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="overline">
                                        {monthly ? 'APORTES MENSAIS' : 'APORTES ANUAIS'}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="overline">PREVIDÊNCIA</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="overline">Patrimônio</Typography>
                                </TableCell>
                                <TableCell style={{ minWidth: 100 }} align="right">
                                    <Badge variant="dot" color="primary">
                                        <Tooltip
                                            title={
                                                <div>
                                                    Patrimônio imobilizado com foco em investimentos rentabilidade fixa
                                                    de 1% acima da infanção
                                                </div>
                                            }
                                        >
                                            <Typography variant="overline">Patrimônio Imobilizado</Typography>
                                        </Tooltip>
                                    </Badge>
                                    <Typography style={{ lineHeight: 1, fontSize: '0.6rem' }}></Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...Array(flow.table.planningDuration)].map((_, i) => (
                                <TableRow
                                    key={`flow-line-${i}`}
                                    className={i === flow.table.planningDuration - 1 ? classes.last : undefined}
                                    style={
                                        i === 9
                                            ? {
                                                borderBottom: `2px solid ${red[500]}`,
                                            }
                                            : undefined
                                    }
                                >
                                    <TableCell align="left">
                                        <Typography className={classes.textSize}>{currentYear + i}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.textSize} align="center">
                                            {formatter.format(
                                                monthly
                                                    ? getMonthly(i, 'incomingProjection')
                                                    : flow.table.incomingProjection[i]
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography className={classes.textSize} color="error">
                                            {formatter.format(
                                                monthly
                                                    ? getMonthly(i, 'fixedCostProjection') +
                                                    getMonthly(i, 'financingProjection') +
                                                    getMonthly(i, 'consortiumProjection')
                                                    : flow.table.fixedCostProjection[i] +
                                                    flow.table.financingProjection[i] +
                                                    flow.table.consortiumProjection[i]
                                            )}
                                        </Typography>
                                        <Typography className={classes.textSize} color="error"></Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {getTypographyColored(
                                            monthly
                                                ? -getMonthly(i, 'insuranceProjection')
                                                : -flow.table.insuranceProjection[i]
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {getTypographyColored(
                                            monthly
                                                ? i == 0
                                                    ? -(flow.table.objectiveProjection[i] / flow.monthsToTheEnd)
                                                    : -(flow.table.objectiveProjection[i] / 12)
                                                : -(flow.table.objectiveProjection[i])
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Badge invisible={invisible(i)} variant="dot" color="primary">
                                            <Tooltip
                                                title={
                                                    monthly
                                                        ? tooltip(flow.table.patrimonyProjection[i], i, true, flow.monthsToTheEnd)
                                                        : tooltip(flow.table.patrimonyProjection[i], i, false, flow.monthsToTheEnd)
                                                }
                                                disableHoverListener={invisible(i)}
                                                interactive
                                            >

                                                {getTypographyColored(
                                                    monthly
                                                        ? (flow.table.incomingProjection[i] - (flow.table.financingProjection[i] + flow.table.fixedCostProjection[i] + flow.table.consortiumProjection[i] + flow.table.objectiveProjection[i]) + flow.table.patrimonyProjection[i].contemplation + (((flow.table.patrimonyProjection[i].financing / 12) * flow.monthsToTheEnd) / flow.monthsToTheEnd) + (((flow.houseSell.value / 12) * flow.monthsToTheEnd) / flow.monthsToTheEnd)) / 12
                                                        : (flow.table.incomingProjection[i] - (flow.table.financingProjection[i] + flow.table.fixedCostProjection[i] + flow.table.consortiumProjection[i] + flow.table.objectiveProjection[i]) + flow.table.patrimonyProjection[i].contemplation + flow.table.patrimonyProjection[i].financing + flow.houseSell.value)
                                                )}
                                            </Tooltip>
                                        </Badge>
                                    </TableCell>
                                    <TableCell align="center">
                                        {getTypographyColored(flow.table.pensionProjection[i])}
                                    </TableCell>
                                    <TableCell align="center">
                                        {getTypographyColored(
                                            flow.table.patrimonyProjection[i].investmentAsset +
                                            flow.table.patrimonyProjection[i].interest
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {getTypographyColored(flow.table.patrimonyProjection[i].fixedAsset)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </LoadingPage>
    );
};
export default Flow;
