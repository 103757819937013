import { Chip, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import { GreyedDateInput, GreyedTextInput } from '../../../global/inputs/GreyedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import PercentageInput from '../../../global/masks/percentageMaskedInput';
import { IFinancingValue } from '../types';
import { useStyles } from './style'

interface IFinancingInputFormProps {
    type: 'sac' | 'price' | 'other';
    name: string;
    addFinancing: (financing: IFinancingValue) => void;
}

const FinancingInputForm: React.FC<IFinancingInputFormProps> = ({ type, name, addFinancing }) => {
    const classes = useStyles();
    const [installment, setInstallment] = React.useState('');
    const [debit, setDebit] = React.useState('');
    const [interestRate, setInterestRate] = React.useState('');
    const [months, setMonths] = React.useState(0);
    const [date, setDate] = React.useState(new Date());
    const [dateDisable, setDateDisable] = React.useState(true);

    const handleMonthChange = (monthsInput: string) => {
        const parsedVal = parseInt(monthsInput, 10);
        if (!Number.isNaN(parsedVal)) {
            setMonths(parsedVal);
        } else {
            setMonths(0);
        }
    };

    const reset = () => {
        setInstallment('');
        setDebit('');
        setInterestRate('');
        setMonths(0);
        setDate(new Date());
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (date == new Date()) {
            let year = date.getFullYear()
            let month = (date.getMonth() + 1)
            let day = date.getDate()

            let dateTomorrow = new Date(year, month, day + 1)
            setDate(new Date(dateTomorrow.toISOString()))
        }
        addFinancing({
            type,
            name,
            installment: unmaskCurrency(installment),
            interestRate: unmaskCurrency(interestRate),
            debit: unmaskCurrency(debit),
            monthsLeft: months,
            startDate: date,
        });
        reset();
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={1}>
                <Grid item xs={12} hidden={type !== 'other'}>
                    <Typography className="mb-2" variant="body2">Valor da Prestação</Typography>
                    <GreyedTextInput
                        color="primary"
                        variant="outlined"
                        size="small"
                        placeholder="Coloque o valor da prestação"
                        InputProps={{
                            inputComponent: CurrencyInput as any,
                            startAdornment: <MoneyAdornment />,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={installment}
                        onChange={event => setInstallment(event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} hidden={type === 'other'}>
                    <Typography className="mb-2" variant="body2">Saldo devedor</Typography>
                    <GreyedTextInput
                        color="primary"
                        variant="outlined"
                        size="small"
                        placeholder="0.00"
                        InputProps={{ startAdornment: <MoneyAdornment />, inputComponent: CurrencyInput as any }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={debit}
                        onChange={event => setDebit(event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid container item spacing={3}>
                    <Grid xs={4} item className={classes.spacedColumn}>
                        <Typography className="mb-2" variant="body2">Prestações restantes</Typography>
                        <div className={classes.monthsInput}>
                            <GreyedTextInput
                                variant="outlined"
                                size="small"
                                type="number"
                                inputMode="numeric"
                                value={months.toString()}
                                onChange={event => handleMonthChange(event.target.value)}
                                InputProps={{
                                    inputProps: { min: 0 },
                                    endAdornment: <div style={{ padding: '0 8px' }}>meses</div>,
                                }}
                            />
                        </div>
                    </Grid>
                    {type !== 'other' ? (
                        <Grid item xs={4} className={classes.spacedColumn}>
                            <Typography className="mb-2" variant="body2">Taxa de juros anual</Typography>
                            <GreyedTextInput
                                required
                                color="primary"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    inputComponent: PercentageInput as any,
                                    inputProps: { max: 100 },
                                    endAdornment: <div style={{ padding: '0 8px' }}>%</div>,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={interestRate}
                                onChange={event => setInterestRate(event.target.value)}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={4} className={classes.spacedColumn}>
                        <Typography className="mb-2" variant="body2">Data de início</Typography>
                        {dateDisable ? (
                            //@ts-ignore
                            <div onClick={() => setDateDisable(false)}>
                                <Typography className="mb-2"
                                    style={{ fontSize: 11, color: grey[500], marginTop: '-9px' }}
                                    variant="subtitle1"
                                >
                                </Typography>
                            </div>
                        ) : null}
                        <GreyedDateInput
                            clearable
                            size="small"
                            disabled={dateDisable}
                            //@ts-ignore
                            onClick={() => setDateDisable(false)}
                            format="dd/MM/yyyy"
                            margin="normal"
                            variant="inline"
                            inputVariant="outlined"
                            InputProps={{
                                className: classes.dateInput,
                            }}
                            id="date-picker-inline"
                            value={date}
                            minDate={new Date(21, 9, 2020)}
                            onChange={d => d && setDate(d as Date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        ></GreyedDateInput>
                    </Grid>
                </Grid>
                <Grid container item spacing={3} xs={12} justify="flex-end">
                    <Grid item xs={type === 'other' ? 4 : 8} />
                    <Grid item xs={type === 'other' ? 8 : 4}>
                        <Typography className="mb-2"
                            style={{
                                fontSize: 11,
                                color: grey[500],
                                marginLeft: type === 'other' ? 10 : 18,
                                lineHeight: 1,
                            }}
                            variant="subtitle1"
                        >
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <button
                type="submit"
                style={{ backgroundColor: 'transparent', border: 0, float: 'right', marginTop: '10px' }}
            >
                <Chip variant="outlined" color="secondary" size="medium" label="Adicionar" clickable></Chip>
            </button>
        </form>
    );
};
export default FinancingInputForm;
